import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hang Squat Clean 5×2\\@80% 1RM`}</p>
    <p>{`Lateral Band Walks 5×6/side`}</p>
    <p>{`then,`}</p>
    <p>{`Death by:`}</p>
    <p>{`Double Unders x10 `}<em parentName="p">{`(1:00-10, 2:00-20, 3:00-30 etc)`}</em></p>
    <p>{`once you fail start… `}<em parentName="p">{`(no rest from double unders to front squats)`}</em></p>
    <p>{`Death by:`}</p>
    <p>{`Front Squats x2 (95/65`}{`#`}{`) `}<em parentName="p">{`(1:00-2, 2:00-4, 3:00-6, 4:00-8 etc)`}</em></p>
    <p>{`Score = total number of complete rounds.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      